<template>
  <div>
    <!-- 顶部 -->
    <div class="banner">
      <img
        class="banner-img"
        src="../../../assets/images/banner/jiudian.png"
        alt=""
      />
    </div>

    <div class="stepA">
      <div class="stepA-a">
        <!-- 红娘五步 -->
        <p class="five">红娘五步</p>
        <div class="step">
          <ul class="yezaifive">
            <li
              class="tab"
              v-for="(item, index) in steps"
              :key="index"
              @mouseout="show(index)"
              :class="{ active: stepindex == index }"
            >
              <p class="num">
                <span>{{ item.num }}</span>
                <img :src="item.src" alt="" />
              </p>
              <span class="min">{{ item.buzhou }}</span>
              <p>
                <img v-if="stepindex != index" :src="jt" alt="" />
                <img v-if="stepindex == index" :src="cfjt" alt="" />
              </p>
            </li>
          </ul>
          <div class="step-yezai-right">
            <!-- 到店验证 -->
            <div class="content" v-if="stepindex == 0">
              <p>建档验证:专业征婚,从懂会员开始</p>
              <p><span class="circle"></span> 多重资料验证</p>
              <p class="tilte">
                会员到店后，为保障征婚信息的真实性，专业红娘将会协助会员完成身份、学历、婚育证明等多重资料验证。安全征婚，为自己也为TA。
              </p>
              <p><span class="circle"></span> 专业红娘服务</p>

              <p class="tilte">
                也在网线下服务红娘团队将为会员提供“三位一体 ”的高端服务模式:
                一位“高端专属红娘”，从“专业配对”、“心灵情感”、“婚恋咨询”
                三个维度。全程为会员提供高品质、全方位的高端婚恋服务。
              </p>
              <p><span class="circle"></span> 定制征婚方案</p>
              <p class="tilte">
                征婚前，专属红娘与会员进行一对一面谈，深入了解会员的性格特点、行为方式及婚恋需求等，为其量身定制征婚方案，并根据征婚后的具体情况具体分析，及时优化征婚方案，实现高效省时地征婚。
              </p>
            </div>
            <!-- 甄选相亲对象 -->
            <div class="content" v-else-if="stepindex == 1">
              <p>甄选对象：专业匹配，精挑细选</p>
              <p><span class="circle"></span> 优质资源</p>
              <p class="tilte">
                红娘服务团队以会员征婚档案为基础，根据会员的征婚需求，倾心打造优质候选会员库，为会员推选优质候选会员。
              </p>
              <p><span class="circle"></span> 专家评测</p>
              <p class="tilte">
                红娘专家团队凭借丰富的婚恋服务经验，对候选会员进行集中面试，全方位、多角度评估其个人背景、性格特质、思想观念等，并依据征婚会员的需求，甄选出最优匹配人选。
              </p>
              <p><span class="circle"></span> 专业推荐</p>
              <p class="tilte">
                高端专属红娘综合会员的自身条件及其征婚需求，以专业的角度、丰富的经验、独到的眼光，为会员快速锁定优质称心会员，客观、及时地将其推荐给征婚会员。
              </p>
            </div>
            <!-- 牵线传情：专业牵线，准确快捷 -->
            <div class="content" v-else-if="stepindex == 2">
              <p>牵线传情：专业牵线，准确快捷</p>
              <p><span class="circle"></span> 传情达意</p>
              <p class="tilte">
                会员从推荐人选中挑选出最心仪对象，专属红娘适时将会员介绍给对方，并重点突出会员的优点和诚心，了解对方的交往意愿，以促进双方进一步交往。专属红娘穿针引线，传情达意，搭建爱的桥梁。
              </p>
              <p><span class="circle"></span>及时反馈</p>
              <p class="tilte">
                在为会员牵线传情的过程中，专属红娘会将对方的交往意愿及其他情况及时、准确、客观地反馈给会员，让会员对自己的征婚进程了如指掌。
              </p>
              <p><span class="circle"></span> 推动见面</p>
              <p class="tilte">
                专属红娘牵线传情，积极部署双方会员约会见面，寻爱之旅，无需等待。
              </p>
            </div>
            <!-- 浪漫约见：专业安排，相伴左右 -->
            <div class="content" v-else-if="stepindex == 3">
              <p>浪漫约见：专业安排，相伴左右</p>
              <p><span class="circle"></span> 私密约会环境</p>
              <p class="tilte">
                也在网各大线下直营门店为尊贵的会员精心打造多种风格、不同主题的私密约见场所。清幽的坏境、柔和的灯光、舒适的坐椅......让约见对象能够在轻松愉悦的氛围中完成首次约会。
              </p>
              <p><span class="circle"></span>细致约会准备</p>
              <p class="tilte">
                约会前，专属红娘为会员做好约会指导，包括着装、言行、话题等方面，让会员在约会时心中有底。
              </p>
              <p><span class="circle"></span> 贴心约会陪伴</p>
              <p class="tilte">
                初次约会，专属红娘将陪伴左右，帮助双方进行更有效的沟通，化解陌生与尴尬，处理应急情况，助约会对象进入最佳状态。
              </p>
            </div>
            <!--  当面撮合：专业撮合，关怀到底-->
            <div class="content" v-else>
              <p>当面撮合：专业撮合，关怀到底</p>
              <p><span class="circle"></span>及时约会反馈</p>
              <p class="tilte">
                约会结束后，专属红娘及时了解双方对彼此的印象，根据各自的交往意愿，为会员分析总结经验，明确下一步征婚方向，提高征婚效率。
              </p>

              <p><span class="circle"></span>积极推进交往</p>
              <p class="tilte">
                会员确定与心仪对象进一步发展后，专属红娘将安排更多约会，助力感情升温，并随时随地为会员提供情感建议，根据交往进度分析、解决问题，推动会员与心仪对象由相识、相知到相恋，循序渐进地发展。
              </p>
              <p><span class="circle"></span> 全程保驾护航</p>
              <p class="tilte">
                在征婚过程中，专属红娘全程相伴，贴心关怀，服务到位，让会员体验到高效、省心、满意的婚恋服务。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 保障步骤 -->
    <div class="steps">
      <!--六重保障  -->
      <p class="five">六重保障</p>
      <div class="ensure">
        <ul>
          <li
            v-for="(item, index) in guarantee"
            :key="index"
            @mouseout="show1(index)"
            :class="{ actives: guaranteeindex == index }"
          >
            <img :src="item.src" alt="" />
            <p>{{ item.title }}</p>
          </li>
        </ul>
        <!-- 真实资料 -->
        <div class="means" v-if="guaranteeindex == 0">
          <p>真实资料</p>
          <p><span class="circle"></span>到店面谈</p>
          <p class="title">
            专属红娘亲自约见VIP会员，通过面谈核实会员个人基本资料，保障征婚安全
          </p>
          <p><span class="circle"></span> 多重认证</p>
          <p class="title">
            专属红娘为每位服务会员完成身份、学历、婚育证明等多重资料验证，保证会员个人资料的真实性。
          </p>
        </div>
        <!-- 精选优质会员 -->
        <div class="means" v-else-if="guaranteeindex == 1">
          <p>精选优质会员</p>
          <p><span class="circle"></span>也在品牌实力保证</p>
          <p class="title">
            也在网是由专业红娘提供服务的相亲网站，成熟的婚配设施及专业的婚恋服务经验，是会员征婚的双重保障。
          </p>
          <p><span class="circle"></span> 多重渠道海量甄选</p>
          <p class="title">
            实力雄厚的也在网专业红娘服务团队，通过多种渠道、多种方式，在各行业海选、网罗大量优质会员。
          </p>
          <p><span class="circle"></span> 百里挑一专业匹配</p>
          <p class="title">
            专属红娘充分综合会员的个人情况和征婚需求，精挑细选高诚意优质会员，为会员快速锁定并推荐人选。
          </p>
        </div>
        <!-- 安全隐私保护 -->
        <div class="means" v-else-if="guaranteeindex == 2">
          <p>安全隐私保护</p>
          <p><span class="circle"></span>私密征婚档案</p>
          <p class="title">
            从初期甄选到后期交往，会员的个人资料绝不会以任何方式、任何渠道泄露。会员征婚档案绝对私密保护。
          </p>
          <p><span class="circle"></span> 独立约会场所</p>
          <p class="title">
            也在网各大线下直营门店精心打造多种风格、不同主题的约会场所，让会员享受独立、安静的私密约会空间。
          </p>
        </div>
        <!-- 专属红娘团队 -->
        <div class="means" v-else-if="guaranteeindex == 3">
          <p>专属红娘团队</p>
          <p><span class="circle"></span>专属红娘团队</p>
          <p class="title">
            专属红娘亲自约见VIP会员，通过面谈核实会员个人基本资料，保障征婚安全。
          </p>
          <p><span class="circle"></span> 多重认证</p>
          <p class="title">
            专属红娘为每位服务会员完成身份、学历、婚育证明等多重资料验证，保证会员个人资料的真实性。
          </p>
        </div>
        <!-- 高效省心省力 -->
        <div class="means" v-else-if="guaranteeindex == 4">
          <p>高效省心省力</p>
          <p><span class="circle"></span>8年专业婚恋服务经验</p>
          <p class="title">
            也在网自2015年成立至今，积累数十年婚恋服务经验，精准匹配、专业甄选，婚配效率高，帮助会员在征婚途中少走弯路，朝着幸福婚姻加速度前行。
          </p>
          <p><span class="circle"></span> 全方位托管式服务</p>
          <p class="title">
            从匹配、甄选、牵线、传情、约见到撮合恋爱，甚至步入婚姻殿堂，征婚中的每一步都非常关键，也在网专属红娘为会员提供全方位托管式服务，会员可省心征婚。
          </p>
        </div>
        <!-- 全程为爱护航 -->
        <div class="means" v-else>
          <p>全程为爱护航</p>
          <p><span class="circle"></span>专属红娘全程协助</p>
          <p class="title">
            征婚过程中，专属红娘全程提供专业服务，贴心关怀。让会员的爱情种子也在网生根发芽、开花结果。
          </p>
          <p><span class="circle"></span> 成功相亲后，免费咨询到结婚</p>
          <p class="title">
            也在网郑重承诺，会员相亲成功后，无论服务是否到期，只要会员有任何婚恋方面的疑问，专属红娘都将免费提供婚恋咨询服务和恋爱指导，帮助会员与爱侣步入婚姻殿堂。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepindex: 0,
      jt: require("@/assets/images/server/jt.png"),
      cfjt: require("@/assets/images/server/cfjt.png"),
      // 红娘五步
      steps: [
        {
          num: "1",
          buzhou: "到店验证建档",
          src: require("../../../assets/images/server/1.png.png"),
        },
        {
          num: "2",
          buzhou: "甄选相亲对象",
          src: require("../../../assets/images/server/2.png.png"),
        },
        {
          num: "3",
          buzhou: "专业牵线传情",
          src: require("../../../assets/images/server/3.png.png"),
        },
        {
          num: "4",
          buzhou: "店内浪漫约见",
          src: require("../../../assets/images/server/4.png.png"),
        },
        {
          num: "5",
          buzhou: "红娘当面撮合",
          src: require("../../../assets/images/server/5.png.png"),
        },
      ],
      guaranteeindex: 0,
      guarantee: [
        {
          title: "真实验证资料",
          src: require("../../../assets/images/server/11.png.png"),
        },
        {
          title: "精选优质会员",
          src: require("../../../assets/images/server/12.png.png"),
        },
        {
          title: "安全隐私保护",
          src: require("../../../assets/images/server/13.png.png"),
        },
        {
          title: "专属红娘团队",
          src: require("../../../assets/images/server/14.png.png"),
        },
        {
          title: "高效省心省力",
          src: require("../../../assets/images/server/15.png.png"),
        },
        {
          title: "全程为爱护航",
          src: require("../../../assets/images/server/16.png.png"),
        },
      ],
    };
  },
  created() {
    this.$store.state.directIndex = 1;
  },
  methods: {
    // 红娘五步骤
    show(index) {
      this.stepindex = index;
    },
    // 六重保障
    show1(index) {
      this.guaranteeindex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-position: center center;
  .banner-img {
    width: 100%;
    height: 100%;
    @media screen {
      min-width: 1920px;
    }
  }
}
//保障
.stepA {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  padding-bottom: 100px;
  .stepA-a {
    width: 1200px;
    margin: 0 auto;
    .five {
      font-size: 34px;
      font-weight: bold;
      color: #555555;
      line-height: 48px;
      text-align: center;
      margin-bottom: 100px;
    }
    .step {
      // margin: 0 auto;
      // width: 1200px;
      height: 630px;
      display: flex;
      justify-content: center;
      background-color: #fff;
      .step-yezai-right {
        background-color: #fff6f7;
        flex: 1;
        // width: 678px;
        .circle {
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #fd686e;
          border-radius: 50%;
          margin-right: 5px;
        }
        .content {
          width: 678px;
          height: 630px;
          box-sizing: border-box;
          color: #555555;
          padding: 40px 32px 0px 32px;
          .tilte {
            margin-left: 20px;
          }
        }
        .content > p:first-child {
          font-size: 30px;
          color: #fd686e;
          line-height: 80px;
        }
        .content > p:nth-child(2n) {
          line-height: 55px;
        }
        .content > p:nth-child(3) {
          line-height: 36px;
        }
        .content > p:nth-child(4) {
          line-height: 55px;
        }
        .content > p:nth-child(5) {
          line-height: 36px;
        }
        .content > p:nth-child(6) {
          line-height: 55px;
        }
        .content > p:last-child {
          line-height: 36px;
        }
      }

      .yezaifive {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // flex: 1;
        // width: 502px;
        li {
          width: 502px;
          height: 110px;
          border-radius: 8px 0px 0px 8px;
          cursor: pointer;
        }
        .tab {
          display: flex;
          align-items: center;
          width: 502px;
          height: 110px;
          border-radius: 8px 0px 0px 8px;
          font-size: 20px;
          font-weight: bold;
          color: #bdbdbd;
          position: relative;
          justify-content: space-around;
          // background-color: #f7f7f7;
          .num {
            display: flex;
            align-items: center;
            span {
              font-size: 34px;
              margin-right: 16px;
            }
          }
        }

        .active {
          background: linear-gradient(270deg, #ffa1a7 0%, #fd686e 100%);
          .min {
            color: #fff;
          }
          .num {
            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
//六重
.steps {
  // background-color: #f7f7f7;
  // padding-top: 100px;
  width: 1200px;
  margin: 0 auto;
  .five {
    font-size: 34px;
    font-weight: bold;
    color: #555555;
    line-height: 48px;
    text-align: center;
    margin-top: 100px;
    // margin-bottom: 20px;
    margin-bottom: 80px;
  }
  .ensure {
    width: 1200px;
    // height: 573px;
    margin: 0 auto;
    ul {
      width: 1200px;
      // height: 276px;
      display: flex;
      li {
        width: 200px;
        height: 276px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: column;
        img {
          width: 114px;
          height: 114px;
          // background: pink;
          margin: 0 auto;
          margin-top: 10px;
          border-radius: 50%;
        }
        p {
          font-size: 16px;
          text-align: center;
          font-weight: 400;
          color: #555555;
          line-height: 22px;
          margin: 0 auto;
          margin-top: 20px;
        }
      }
      .actives {
        background: linear-gradient(270deg, #ffa1a7 0%, #fd686e 100%);
        :last-child {
          color: #fff;
        }
      }
    }

    // .solids {
    //   width: 1px;
    //   height: 93px;
    //   background: #ececec;
    //   margin-top: 75px;
    // }
    .means {
      // width: 1920px;
      // margin-top: 20px;
      height: 399px;
      background: #fff6f7;
      padding-top: 42px;
      padding-left: 70px;
      padding-right: 70px;
      box-sizing: border-box;
      .circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: #fd686e;
        border-radius: 50%;
        margin-right: 10px;
      }
      .title {
        margin-left: 20px;
      }
      // p {
      //   margin-left: 70px;
      // }
      :first-child {
        font-size: 30px;
        font-weight: 400;
        color: #fd686e;
        line-height: 60px;
        // margin-top: -20px;
      }
      :nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        color: #555555;
        line-height: 60px;
      }
      :nth-child(3) {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #555555;
        line-height: 20px;
      }
      :nth-child(4) {
        font-size: 16px;
        font-weight: 400;
        color: #555555;
        line-height: 60px;
      }
      :nth-child(5) {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #555555;
        line-height: 20px;
      }
      :nth-child(6) {
        font-size: 16px;
        font-weight: 400;
        color: #555555;
        line-height: 60px;
      }
      :nth-child(7) {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #555555;
        line-height: 20px;
      }
    }
  }
}
</style>
